import { NetEnvChainConfigLookup } from '../NetEnvChainConfigLookup'

import { CHIHUAHUA_TESTNET } from './chihuahua-testnet'

export default <NetEnvChainConfigLookup>{
  localnet: CHIHUAHUA_TESTNET,
  devnet: CHIHUAHUA_TESTNET,
  testnet: CHIHUAHUA_TESTNET,
  mainnet: CHIHUAHUA_TESTNET,
}
