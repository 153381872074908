import { SIGN_IN, SIGN_UP } from '@/utils/constants/text/auth'
import { navbarList } from '@/utils/constants/text/navbarList'
import { chakra } from '@chakra-ui/react'
import React from 'react'
import CustomButton from '../button'
import { DarkModeSwitch } from '../DarkModeSwitch'
import Navbar from './Navbar'

const Hero = () => {
  return <Navbar key="12" menus={navbarList} />
}

export default Hero
