import { NetEnvChainConfigLookup } from '../NetEnvChainConfigLookup'

import { KI_TESTNET } from './ki-testnet'

export default <NetEnvChainConfigLookup>{
  localnet: KI_TESTNET,
  devnet: KI_TESTNET,
  testnet: KI_TESTNET,
  mainnet: KI_TESTNET,
}
