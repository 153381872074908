import { Coin } from '@keplr-wallet/types/build/cosmjs'
import { atom, selector } from 'recoil'
import { SdkConfig } from '~/domains/core/envs'

export const walletAddressState = atom<string>({
  key: 'walletAddressState',
  default: '',
})

export const balanceLoadState = atom<boolean>({
  key: 'balanceLoadState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
})

type NewCoin = Coin & { displaySymbol: string }

export const balanceState = atom<readonly NewCoin[]>({
  key: 'balances', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
})

interface IGlobalContext {
  collateralTokenTypes: string
  pricesList: string
  envConfig: string
  hasLoadedData: boolean
}

export const initialDataState = atom<IGlobalContext>({
  key: 'initialData', // unique ID (with respect to other atoms/selectors)
  default: {
    collateralTokenTypes: '',
    pricesList: '',
    envConfig: '',
    hasLoadedData: false,
  }, // default value (aka initial value)
})
