// import { getDefaultProvider } from 'ethers'
import type { PropsWithChildren } from 'react'
import { WagmiConfig, configureChains, createClient } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'

import { publicProvider } from 'wagmi/providers/public'

const { provider, webSocketProvider } = configureChains(
  [mainnet, goerli],
  [
    // to do: fix using our own alchemy api key instead of the community key
    // alchemyProvider({ apiKey: process.env.ALCHEMY_API_KEY }),
    infuraProvider({ apiKey: process.env.INFURA_API_KEY }),
    // publicProvider(),
  ]
)
const client = createClient({
  provider,
  webSocketProvider,
  autoConnect: true,
  connectors: [
    new MetaMaskConnector(),
    new CoinbaseWalletConnector({
      options: {
        appName: 'sifchain DEX',
      },
    }),
    new WalletConnectConnector({
      options: {
        qrcode: true,
      },
    }),
  ],
})

export const WagmiProvider = (props: PropsWithChildren<unknown>) => {
  return <WagmiConfig client={client}>{props.children}</WagmiConfig>
}
