import { NetworkKind } from '~/entities/Network'
import {
  AkashChain,
  BandChain,
  BaseChain,
  BitsongChain,
  CerberusChain,
  ChihuahuaChain,
  ComdexChain,
  CosmoshubChain,
  CryptoOrgChain,
  EmoneyChain,
  EthereumChain,
  EvmosChain,
  GravityChain,
  IrisChain,
  IxoChain,
  JunoChain,
  KiChain,
  LikecoinChain,
  OsmosisChain,
  PersistenceChain,
  RegenChain,
  SecretChain,
  SentinelChain,
  SifchainChain,
  StargazeChain,
  StarnameChain,
  TerraChain,
} from './chains'

export const NETWORK_CHAIN_LOOKUP: Record<NetworkKind, typeof BaseChain> = {
  sifchain: SifchainChain,
  ethereum: EthereumChain,
  cosmoshub: CosmoshubChain,
  iris: IrisChain,
  akash: AkashChain,
  sentinel: SentinelChain,
  'crypto-org': CryptoOrgChain,
  persistence: PersistenceChain,
  regen: RegenChain,
  osmosis: OsmosisChain,
  terra: TerraChain,
  juno: JunoChain,
  ixo: IxoChain,
  band: BandChain,
  likecoin: LikecoinChain,
  emoney: EmoneyChain,
  starname: StarnameChain,
  bitsong: BitsongChain,
  cerberus: CerberusChain,
  comdex: ComdexChain,
  chihuahua: ChihuahuaChain,
  ki: KiChain,
  stargaze: StargazeChain,
  evmos: EvmosChain,
  secret: SecretChain,
  gravity: GravityChain,
}

export * from './chains'
