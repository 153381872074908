import { NetEnvChainConfigLookup } from '../NetEnvChainConfigLookup'

import { STARGAZE_TESTNET } from './stargaze-testnet'

export default <NetEnvChainConfigLookup>{
  localnet: STARGAZE_TESTNET,
  devnet: STARGAZE_TESTNET,
  testnet: STARGAZE_TESTNET,
  mainnet: STARGAZE_TESTNET,
}
