import { NetEnvChainConfigLookup } from '../NetEnvChainConfigLookup'

import { COMDEX_TESTNET } from './comdex-testnet'

export default <NetEnvChainConfigLookup>{
  localnet: COMDEX_TESTNET,
  devnet: COMDEX_TESTNET,
  testnet: COMDEX_TESTNET,
  mainnet: COMDEX_TESTNET,
}
