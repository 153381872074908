import { Asset } from '../../../packages/common/src'

export const ROWAN = Asset({
  symbol: 'rowan',
  name: 'Rowan',
  decimals: 18,
  network: 'sifchain',
  homeNetwork: 'sifchain',
  displaySymbol: 'ROWAN',
})
