import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from '@chakra-ui/react'

// Global style overrides
import styles from './styles'

// Foundational style overrides
import borders from './foundations/borders'
import spacing from './foundations/spacing'
import zIndices from './foundations/z-index'
import radii from './foundations/radius'
import colors from './foundations/colors'
import fonts from './foundations/fonts'
import fontSizes from './foundations/fontSizes'
import fontWeights from './foundations/fontWeights'
import lineHeights from './foundations/lineHeights'
import letterSpacings from './foundations/letterSpacings'
import breakpoints from './foundations/breakpoints'

// Component style overrides
import Button from './components/button'

const overrides = {
  styles,
  borders,
  spacing,
  zIndices,
  radii,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  breakpoints,
  // Other foundational style overrides go here
  components: {
    Button,
    // Other components go here
  },
}

export default extendTheme(
  overrides,
  withDefaultColorScheme({
    colorScheme: 'brand.primary',
    components: ['Switch'],
  })
)
