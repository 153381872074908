import { NetEnvChainConfigLookup } from '../NetEnvChainConfigLookup'

import { BITSONG_TESTNET } from './bitsong-testnet'

export default <NetEnvChainConfigLookup>{
  localnet: BITSONG_TESTNET,
  devnet: BITSONG_TESTNET,
  testnet: BITSONG_TESTNET,
  mainnet: BITSONG_TESTNET,
}
