import { Disclosure, Menu } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import dynamic from 'next/dynamic'
import {
  AppearTransition,
  DotsVerticalIcon,
  SifchainLogoSmall,
  SurfaceB,
  useWindowSize,
} from '@sifchain/ui'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'

import WalletConnector from '../WalletConnector'

const BannerNoSRR = dynamic(() => import('./Banner'), {
  ssr: false,
})
// import { useRowanPriceQuery, useTVLQuery } from "~/domains/clp/hooks";
// import { useFeatureFlag } from "~/lib/featureFlags";

type Menu = {
  id: string
  name: string
  path: string
}

interface IMenuProps {
  menus: Menu[]
}

const Header = ({ menus }: IMenuProps) => {
  const windowSize = useWindowSize()
  const isMarginStandaloneOn = false
  const linkLogo = '/'

  return (
    <>
      <header className="fixed left-0 right-0 top-0 z-10 grid bg-black md:p-4">
        {Boolean(windowSize.width) && (
          <Disclosure
            as="div"
            className="block justify-between shadow-none md:flex md:items-center md:gap-8 md:pb-2 md:shadow-inset-border"
            defaultOpen={windowSize.width >= 768}
          >
            {({ open }) => (
              <>
                <section className="flex items-center justify-between shadow-inset-border md:grid md:place-items-center md:shadow-none">
                  <Link
                    href={linkLogo}
                    className="flex items-center gap-4 p-2 md:p-1"
                  >
                    <SifchainLogoSmall className="inline-block text-[44px]" />
                    {isMarginStandaloneOn && (
                      <h1 className="pl-3 text-2xl font-semibold">Margin</h1>
                    )}
                  </Link>
                  <div className="md:hidden">
                    <Disclosure.Button className="p-4">
                      {open ? (
                        <XMarkIcon className="h-6 w-6" />
                      ) : (
                        <ChevronDownIcon className="h-6 w-6" />
                      )}
                    </Disclosure.Button>
                  </div>
                </section>
                <AppearTransition show={open}>
                  <Disclosure.Panel
                    className="absolute left-0 right-0 top-14 bg-black md:relative md:top-0 md:flex md:w-full"
                    static={windowSize.width >= 768}
                  >
                    <div className="4xl:gap-8 grid gap-4 p-4 md:flex md:w-full md:p-0 xl:gap-6">
                      <Nav menus={menus} />
                      <WalletConnector />
                    </div>
                  </Disclosure.Panel>
                </AppearTransition>
              </>
            )}
          </Disclosure>
        )}
      </header>

      <div className="h-72 "></div>
      <BannerNoSRR />
    </>
  )
}

interface INavProps {
  menus: Menu[]
  visibleItems?: number
}

const Nav = ({ menus, visibleItems = 9 }: INavProps) => {
  const router = useRouter()
  const currentPath = router.asPath

  return (
    <nav className="w-full md:flex md:flex-1 md:justify-center">
      <ul className="4xl:gap-8 grid items-center gap-2 md:flex md:gap-4 xl:gap-5">
        {menus.slice(0, visibleItems).map(({ name, path }) => (
          <li key={name}>
            <Link
              href={path}
              role="navigation"
              className={clsx(
                'flex items-center gap-4 rounded-md p-2 transition-all hover:bg-gray-800 hover:opacity-80',
                {
                  'bg-gray-600': currentPath === path,
                }
              )}
            >
              <span className="text-m font-semibold text-gray-200">{name}</span>
            </Link>
          </li>
        ))}
        {menus.length > visibleItems ? (
          <li className="relative flex items-center">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button
                    className={clsx('rotate-90', {
                      'rounded-full ring-1 ring-gray-50 ring-offset-4 ring-offset-gray-800':
                        open,
                    })}
                  >
                    <DotsVerticalIcon className="h-4 w-4" />
                  </Menu.Button>
                  <Menu.Items
                    as={SurfaceB}
                    className="absolute right-0 top-7 z-10 grid gap-2 p-2"
                  >
                    {menus.slice(visibleItems).map(({ name, path }) => (
                      <Menu.Item key={name}>
                        <Link
                          href={path}
                          role="navigation"
                          className={clsx(
                            'flex items-center gap-4 rounded-md p-2 transition-all hover:bg-gray-800 hover:opacity-80',
                            {
                              'bg-gray-600': currentPath === path,
                            }
                          )}
                        >
                          <span className="text-sm font-semibold text-gray-200">
                            {name}
                          </span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </>
              )}
            </Menu>
          </li>
        ) : null}
      </ul>
    </nav>
  )
}

export default Header
