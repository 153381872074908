import { NetEnvChainConfigLookup } from '../NetEnvChainConfigLookup'

import { CERBERUS_TESTNET } from './cerberus-testnet'

export default <NetEnvChainConfigLookup>{
  localnet: CERBERUS_TESTNET,
  devnet: CERBERUS_TESTNET,
  testnet: CERBERUS_TESTNET,
  mainnet: CERBERUS_TESTNET,
}
