import React, { useEffect } from 'react'
import { getDYDXCoinLists } from '~/serverUtils/marginUtils'
import { useTokenRegistryQuery } from '~/domains/tokenRegistry'
import { TARGET_COIN_LIST } from '@/utils/constants/constants'
import { getNetworkName, setEnvCookie } from '~/common'
import { getRegistryEntries } from '~/serverUtils/serverUtils'
import { AppCookies } from '~/common/config/AppCookies'
import { getEnvConfig } from '~/utils/clientUtils'
import { useRecoilState } from 'recoil'
import { useDexEnvironment } from '~/domains/core/envs'
import {
  balanceLoadState,
  balanceState,
  initialDataState,
  walletAddressState,
} from '~/recoil/atoms'
import { getAllBalances } from '~/utils/clientUtils'

export const GlobalDataProvider = (props: any) => {
  const [_myState, setMyState] = useRecoilState(initialDataState)

  const { data: env } = useDexEnvironment()!
  const sifChainId = env?.sifChainId!
  const sifUrl = env?.sifRpcUrl!
  const [_currentAddress, setCurrentAddress] =
    useRecoilState(walletAddressState)

  const [_balances, setBalances] = useRecoilState(balanceState)
  const [_isLoadingBalance, setIsLoadingBalance] =
    useRecoilState(balanceLoadState)

  const { data: registry } = useTokenRegistryQuery()

  type RegistryEntries = ReturnType<typeof useTokenRegistryQuery>['data']

  const loadKeplrData = async (
    chainId: string,
    chainUrl: string,
    registryEntries: NonNullable<RegistryEntries>
  ) => {
    setIsLoadingBalance(true)
    try {
      if (!window.keplr) {
        alert('Please install keplr extension')
      } else {
        await window.keplr.enable(chainId)
        const offlineSigner = window.keplr.getOfflineSigner(chainId)
        const accountAddress = (await offlineSigner.getAccounts())[0].address
        setCurrentAddress(accountAddress)
        const accountBalances = await getAllBalances(
          offlineSigner,
          chainUrl,
          accountAddress
        )
        let newBalances = accountBalances
          .filter(
            el =>
              registryEntries.find(reg => el.denom === reg.denom) !== undefined
          )
          .map(el => {
            let item = registryEntries.find(reg => el.denom === reg.denom)
            let displaySymbol = el.denom
            if (item != null) {
              displaySymbol = item.displaySymbol
            }
            return {
              ...el,
              displaySymbol,
            }
          })
        setBalances(newBalances)
      }
    } catch (ex) {
      // reject connect
      console.error('ex', ex)
    } finally {
      setIsLoadingBalance(false)
    }
  }

  useEffect(() => {
    if (sifChainId != null && sifUrl != null && registry != null) {
      let fetchKeplrData = () => loadKeplrData(sifChainId, sifUrl, registry)
      // Emits when user switches their key store/account
      window.addEventListener('keplr_keystorechange', fetchKeplrData)
      fetchKeplrData()
      return () => {
        window.removeEventListener('keplr_keystorechange', fetchKeplrData)
      }
    }
  }, [sifChainId, sifUrl, registry])

  useEffect(() => {
    fetchInitialData()
  }, [])

  // useEffect(() => {
  //   if (myState.hasLoadedData) {
  //     const updatePricesList = setInterval(async () => {
  //       let pricesListData = await getDYDXCoinLists()
  //       setMyState({
  //         collateralTokenTypes: myState.collateralTokenTypes,
  //         pricesList: JSON.stringify({
  //           data: pricesListData,
  //         }),
  //         hasLoadedData: myState.hasLoadedData,
  //       })
  //     }, PRICES_LIST_RELOAD_INTERVAL)

  //     return () => clearInterval(updatePricesList)
  //   }
  // }, [myState.hasLoadedData])

  const fetchInitialData = async () => {
    try {
      const sifEnv = getNetworkName()
      if (AppCookies().getEnv() === undefined) setEnvCookie(sifEnv!)
      const envConfig = await getEnvConfig({ environment: sifEnv! })
      const [collateralTokenTypesData, pricesListData] = await Promise.all([
        getRegistryEntries(envConfig?.sifRpcUrl!),
        getDYDXCoinLists(TARGET_COIN_LIST.map(el => el.symbol).join(',')),
      ])
      setMyState({
        collateralTokenTypes: JSON.stringify({
          data: collateralTokenTypesData ?? [],
        }),
        pricesList: JSON.stringify({
          data: pricesListData,
        }),
        envConfig: JSON.stringify({ data: envConfig ?? '' }),
        hasLoadedData: true,
      })
    } catch (ex) {
      // Removed as caused infinite loop once rate limited
      console.error(ex, 'Retry fetchInitialData again')
    }
  }

  return <>{props.children}</>
}

export default GlobalDataProvider
