import { Fragment, useState, useEffect } from 'react'
import { ComposeProviders } from '@sifchain/ui'
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Hero from '../components'
import theme from '../theme'
import { CookiesProvider } from 'react-cookie'
import { CosmConnectProvider } from '~/lib/cosmConnect'
import { WagmiProvider } from '~/lib/wagmi'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { RecoilRoot, useRecoilState } from 'recoil'

import '~/styles/globals.css'
import 'tailwindcss/tailwind.css'

import GlobalDataProvider from '../recoil/GlobalDataProvider'
import EnvSwitcher from '@/components/EnvSwticher/EnvSwitcher'
import { initialDataState } from '~/recoil/atoms'
import { getAllBalances } from '~/utils/clientUtils'

const Loading = () => {
  const router = useRouter()

  const [{ hasLoadedData }] = useRecoilState(initialDataState)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    localStorage.setItem('chakra-ui-color-mode', 'dark')
    const handleStart = (_url: string) => {
      setLoading(true)
    }
    const handleComplete = (_url: string) => {
      setLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [])

  let showLoaderDiv = hasLoadedData && loading
  if (showLoaderDiv) {
    return (
      <LoaderDiv id="loading">
        <Spinner size="xl" />
      </LoaderDiv>
    )
  } else {
    return <div />
  }
}

const DisclaimerModalNoSRR = dynamic(
  () => import('../components/DisclaimerModal'),
  {
    ssr: false,
  }
)

type Props = {
  children: JSX.Element[] | JSX.Element
}

const MyLoader: React.FC<Props> = ({ children }) => {
  const [{ hasLoadedData }] = useRecoilState(initialDataState)
  if (!hasLoadedData) {
    return <div />
  } else {
    return <>{children}</>
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnWindowFocus: false },
        },
      })
  )

  return (
    <ComposeProviders
      providers={[
        typeof window !== 'undefined' ? CosmConnectProvider : Fragment,
        WagmiProvider,
      ]}
    >
      <RecoilRoot>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider resetCSS theme={theme}>
              <Hero />
              <GlobalDataProvider>
                <Loading />
                <MyLoader>
                  <Component {...pageProps} />
                </MyLoader>
              </GlobalDataProvider>
              <DisclaimerModalNoSRR />
              <EnvSwitcher />
            </ChakraProvider>
          </QueryClientProvider>
        </CookiesProvider>
      </RecoilRoot>
    </ComposeProviders>
  )
}

const LoaderDiv = styled.div`
  z-index: 100;
  position: fixed;
  background: rgba(256, 256, 256, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default MyApp
